<template>
	<div class="notFound">404 找不到页面</div>
</template>

<script>
	export default {
		name: "notFound"
	};
</script>

<style lang="scss">
	.notFound {
		font-size: 36px;
		color: #000;
	}
</style>
